// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-reviews-jsx": () => import("./../src/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-reviews-jsx" */),
  "component---src-pages-services-jsx": () => import("./../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

